<!--
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2025-01-21 10:21:44
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-01-21 10:58:01
 * @FilePath: /ww-swisstyle/src/views/aboutUs.vue
 * @Description: 关于我们页面
-->
<template>
    <div class="about-container">
        <div class="banner">
            <img src="@/assets/aboutUs/banner.png" alt="">
        </div>
        <div class="title warp">
            <div class="content">
                <div class="desc">
                    <p>{{ $t('aboutUs.desc1') }}</p>
                    <h2>{{ $t('aboutUs.highlight') }}</h2>
                </div>
            </div>
        </div>
        <div class="banner">
            <img src="@/assets/aboutUs/banner2.png" alt="">
        </div>
        <div class="title warp">
            <div class="content">
                <div class="desc">
                    <p>{{ $t('aboutUs.desc2') }}</p>
                    <p>{{ $t('aboutUs.desc3') }}</p>
                    <p>{{ $t('aboutUs.desc4') }}</p>
                    <p>{{ $t('aboutUs.desc5') }}</p>
                    <p class="name">{{ $t('aboutUs.signature') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<style lang="less" scoped>
.about-container {
    width: 100%;
    min-height: 100vh;
    background: #fff;

    .banner {
        width: 100%;

        img {
            width: 100%;
            display: block;
        }
    }

    .warp {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .title {
        padding: 60px 0;

        .content {

            margin: 0 auto;




            .desc {
                p {
                    font-size: 18px;
                    line-height: 1.8;
                    color: #666;
                    margin-bottom: 40px !important;
                }
                .name {
                    font-weight: 500;
                    font-size: 20px;
                }

                h2 {
                    font-size: 2.0rem;
                    color: #333;
                   
                    margin-bottom: 30px;
                    font-weight: 600;
                }
            }
        }
    }
}

// 响应式适配
@media (max-width: 768px) {
    .about-container {
        .title {
            padding: 40px 0;

            .content {
                h2 {
                    font-size: 1.8rem;
                    margin-bottom: 20px;
                }

                .desc {
                    p {
                        font-size: 16px;
                        line-height: 1.6;
                        margin-bottom: 20px;
                        padding: 0 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .about-container {
        .title {
            padding: 30px 0;

            .content {
                h2 {
                    font-size: 1.6rem;
                    margin-bottom: 15px;
                }

                .desc {
                    p {
                        font-size: 14px;
                        line-height: 1.5;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
</style>
